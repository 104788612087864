import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import http from '../../services/http.service';
import { API, HTTP_STATUS } from '../../constans/http';
import ResendLink from '../../components/ResendLink/ResendLink';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';
import { BLOCKED_USER_LINK, VERIFICATION_LINK } from '../../constans/links';
import StorageService from '../../services/storage.service';

const ResendPwdLink = () => {
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    const resetPwdEmail = StorageService().get(
      LOCALSTORAGE_KEYS.RESET_PWD_EMAIL,
    );
    if (resetPwdEmail) {
      setUserEmail(resetPwdEmail);
    }
  }, []);

  const formSubmitHandler = (values, setServerError) => {
    http
      .post(API.PASSWORD_RESET, { ...values })
      .then(() => {
        navigate('/password-notif');
      })
      .catch(({ message, status }) => {
        if ([HTTP_STATUS.BLOCKED_USER].includes(status)) {
          navigate(BLOCKED_USER_LINK);
        } else if ([HTTP_STATUS.UNVERIFIED].includes(status)) {
          navigate(VERIFICATION_LINK, { state: { pwdRedirect: true } });
        } else {
          setServerError(message);
        }
      });
  };

  return <ResendLink email={userEmail} submitHandler={formSubmitHandler} />;
};

export default ResendPwdLink;
